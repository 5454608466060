
import { InteractionType } from "@azure/msal-browser";
import { LogLevel, TdoeAuthConfig } from "@tdoe/design-system";

const authConfig: TdoeAuthConfig = {
  authEnabled: false,
  projectKey: 'C85EF6CC-08AB-4307-A897-1D410FFBB56E',
  logLevel: LogLevel.debug,
  takeOverConsoleLog: false,
  clientId: '00000000-0000-0000-0000-000000000000',
  protectedResources: [],
  interactionType: InteractionType.Redirect,
  redirectUri: 'https://uat-ccms-search-api.tneducation.net/api',
}

export const environment = {
    production: false,
    authConfig: authConfig,
    apiUrl: 'https://uat-ccms-search-api.tneducation.net/api',
    fake: false
  };
